@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* You can add global styles to this file, and also import other style files */
// $link-color: #ffffff;


//commented out the import of bootstrap5 scss utilities below due to conflicts with the admin-lte css
//@import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/ionicons/css/ionicons.min.css";
@import "../node_modules/admin-lte/dist/css/adminlte.min.css";
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~ngx-toastr/toastr';
@import './assets/scss/colors.scss';
@import './assets/scss/theme.scss';
@import './assets/scss/custom.scss';
@import './assets/scss/responsive.scss';
@import './assets/scss/custom-theme.scss';

::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  // background: transparent;
  /* optional: just make scrollbar invisible */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/material.woff2) format('woff2');
}

.scrollable {
    & {
        scrollbar-width: auto !important;
        scrollbar-color: #787676 #ffffff !important;
    }
    
    &::-webkit-scrollbar {
        width: 16px !important;
    }
    
    &::-webkit-scrollbar-track {
        background: #ffffff !important;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #787676 !important;
        border-radius: 10px !important;
        border: 3px solid #ffffff !important;
    }
}

.clinical-quickview-btn {
  i:before {
    content: "\f107";
  }

  &.collapsed {
    i::before {
      content: '\f104';
    }
  }
}

.image-annotator {
  .toolbar  {
    display: none !important;
  }
}

/* optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
//   background: #ff0000;
// }

.card .card-body{
  border: 1px solid var(--mwell-border-color);
  border-top: none;
  border-radius: 0 0 2px 2px;
  
}

.card .card-header{
  background-color: #F7F7F7;
  border: 1px solid var(--mwell-border-color);

}

.card {
  @media only screen and (max-width: 768px){
    overflow: visible !important;
    height: 100% !important;
  }
}