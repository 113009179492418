$theme-colors: (
  "primary": #035099,
  "secondary": #34abdf,
  "success": #27ae60,
  "warning": #ffa800,
  "danger": #eb5757,
  "light": #ffffff,
  "dark": #A8A8A8
);

@function theme-color($color-name) {
  @return map-get($theme-colors, $color-name);
}

@function theme-contrast-color($color-name) {
  $color: theme-color($color-name);
  @if (lightness($color) > 68%) {
    @return #000; // Dark text for light backgrounds
  } @else {
    @return #fff; // Light text for dark backgrounds
  }
}

// Generate background color classes
@each $name, $color in $theme-colors {
  .bg-#{$name} {
    background-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate text color classes
@each $name, $color in $theme-colors {
  .text-#{$name} {
    color: theme-color($name) !important;
  }
}

// Generate button color classes
@each $name, $color in $theme-colors {
  .btn-#{$name} {
    background-color: theme-color($name) !important;
    border-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
  .btn-#{$name}:hover {
    background-color: darken(theme-color($name), 10%) !important;
    border-color: darken(theme-color($name), 10%) !important;
    color: theme-contrast-color($name) !important;
  }
}


// Generate border color classes
@each $name, $color in $theme-colors {
  .border-#{$name} {
    border-color: theme-color($name) !important;
  }
}

// Generate hover state classes
@each $name, $color in $theme-colors {
  .hover-#{$name}:hover {
    background-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate focus state classes
@each $name, $color in $theme-colors {
  .focus-#{$name}:focus {
    background-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate active state classes
@each $name, $color in $theme-colors {
  .active-#{$name}.active {
    background-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate outline button color classes
@each $name, $color in $theme-colors {
  .btn-outline-#{$name} {
    color: theme-color($name) !important;
    border-color: theme-color($name) !important;
  }
  .btn-outline-#{$name}:hover {
    background-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate alert color classes
@each $name, $color in $theme-colors {
  .alert-#{$name} {
    background-color: theme-color($name) !important;
    border-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate badge color classes
@each $name, $color in $theme-colors {
  .badge-#{$name} {
    background-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate link color classes
@each $name, $color in $theme-colors {
  .link-#{$name} {
    color: theme-color($name) !important;
  }
  .link-#{$name}:hover {
    color: darken(theme-color($name), 10%) !important; // Darken color on hover
  }
}

// Generate card color classes
@each $name, $color in $theme-colors {
  .card-#{$name} {
    background-color: theme-color($name) !important;
    border-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate list group item color classes
@each $name, $color in $theme-colors {
  .list-group-item-#{$name} {
    background-color: theme-color($name) !important;
    border-color: theme-color($name) !important;
    color: theme-contrast-color($name) !important;
  }
}

// Generate nav link color classes
@each $name, $color in $theme-colors {
  .nav-link-#{$name} {
    color: theme-color($name) !important;
  }
  .nav-link-#{$name}:hover {
    color: darken(theme-color($name), 10%) !important; // Darken color on hover
  }
}

ul.custom-breadcrumb li > a {
  color: theme-color(primary) !important;
}

table.table tr td > a {
  color: theme-color(primary) !important;
}


.bordered-gray {
  border: 1px solid var(--mwell-border-color)!important;
}