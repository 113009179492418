:root {
    --mwell-text-color: #4f4f4f;
    --mwell-input-color: #9d9d9d;
    --mwell-text-caption-color: #808080;
    --mwell-primary-color: #035099;
    --mwell-secondary-color: #34abdf;
    --mwell-success-color: #27ae60;
    --mwell-warning-color: #ffa800;
    --mwell-danger-color: #eb5757;
    --mwell-light-color: #ffffff;
    --mwell-dark-color: #404040;
    --mwell-border-color: #aeadad;
    --mwell-content-wrapper-color: #f1f1f1;
    --mwell-card-color: #ECEDEE;
}
